<template>
  <div>
    <div class="d-flex mb-3 justify-content-center">
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded bg-primary d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"
        >
          1
        </div>
        Create Order
        <feather-icon icon="ChevronRightIcon" size="35" class="mx-2" />
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded bg-primary d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"
        >
          2
        </div>
        Key in Details
        <feather-icon icon="ChevronRightIcon" size="35" class="mx-2" />
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded notActive d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"
        >
          3
        </div>
        Finalize Order
        <feather-icon icon="ChevronRightIcon" size="35" class="mx-2" />
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div
          class="rounded notActive d-flex icon align-items-center justify-content-center mr-2 text-white font-weight-bold"
        >
          4
        </div>
        Proceed Payment
      </div>
    </div>
    <validation-observer ref="simpleRules">
      <b-card>
        <div class="d-flex justify-content-center align-items-center">
          <div
            class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
          >
            <feather-icon icon="CalendarIcon" size="35" color="white" />
          </div>
          <h3 class="mr-5 text">Pick Up Date</h3>
          <div class="w-75">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Pick Up Date"
                rules="required"
              >
                <flat-pickr
                  v-model="form.pickup_datetime"
                  class="form-control mt-1"
                  placeholder="Choose Date"
                  readonly="readonly"
                  :config="{
                    minDate: 'today',
                  }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>
      </b-card>
      <b-card>
        <div class="d-flex justify-content-center align-items-center">
          <div
            class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
          >
            <feather-icon icon="TruckIcon" size="35" color="white" />
          </div>
          <h3 class="mr-5 text">Origin Point</h3>
          <div class="w-75">
            <div>
              <validation-provider
                #default="{ errors }"
                name="Origin Point"
                rules="required"
              >
                <select
                  class="form-control form-select rounded"
                  v-model="form.user_id"
                >
                  <option value="" disabled>Select Origin Point</option>
                  <option
                    v-for="address in address"
                    :key="address.id"
                    :value="address"
                  >
                    {{ address.name }} | {{ address.branch.address }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
        </div>
      </b-card>
      <b-card>
        <div class="d-flex justify-content-center">
          <div
            class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
          >
            <feather-icon icon="HomeIcon" size="35" color="white" />
          </div>
          <h3 class="mr-5 text">Sender's Details</h3>
          <div class="w-75">
            <div>
              <b-row class="mb-2">
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Sender's First Name"
                    rules="required"
                  >
                    <input
                      type="text"
                      class="form-control mr-2"
                      placeholder="First Name"
                      aria-label="First Name"
                      v-model="form.sender_first_name"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Sender's Last Name"
                    rules="required"
                  >
                    <input
                      type="text"
                      class="form-control mr-2"
                      placeholder="Last Name"
                      aria-label="Last Name"
                      v-model="form.sender_last_name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <div class="mb-2">
                <validation-provider
                  #default="{ errors }"
                  name="Sender's Phone Number"
                  rules="required"
                >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Phone Number"
                    aria-label="Phone Number"
                    v-model="form.sender_phone_number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <b-row class="mb-2">
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Sender's Unit No."
                    rules="required"
                  >
                    <input
                      type="text"
                      class="form-control mr-2"
                      placeholder="Unit No."
                      aria-label="Unit No."
                      v-model="form.sender_unit_number"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Sender's Address Line 1"
                    rules="required"
                  >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Address Line 1"
                      aria-label="Address Line 1"
                      v-model="form.sender_address_line_1"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <div class="mb-2">
                <validation-provider
                  #default="{ errors }"
                  name="Sender's Address Line 2"
                  rules="required"
                >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Address Line 2"
                    aria-label="Address Line 2"
                    v-model="form.sender_address_line_2"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <b-row class="mb-2">
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Sender's Postcode"
                    rules="required"
                  >
                    <input
                      type="text"
                      class="form-control mr-2"
                      placeholder="Postcode"
                      aria-label="Postcode"
                      v-model="form.sender_postcode"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Sender's Area"
                    rules="required"
                  >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Area"
                      aria-label="Area"
                      v-model="form.sender_area"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Sender's State"
                    rules="required"
                  >
                    <select
                      class="form-control form-select rounded mr-2"
                      v-model="form.sender_state"
                    >
                      <option value="" disabled selected>State</option>
                      <option v-for="(state, index) in state" :key="index">
                        {{ state.state_name }}
                      </option>
                    </select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Sender's Country"
                    rules="required"
                  >
                    <select
                      class="form-control form-select rounded"
                      v-model="form.sender_country"
                    >
                      <option value="" disabled selected>Country</option>
                      <option v-for="(country, index) in country" :key="index">
                        {{ country.value }}
                      </option>
                    </select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-card>
      <b-card>
        <div class="d-flex justify-content-center">
          <div
            class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
          >
            <feather-icon icon="HomeIcon" size="35" color="white" />
          </div>
          <h3 class="mr-5 text">Recipient's Details</h3>
          <div class="w-75">
            <div>
              <b-row class="mb-2">
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient's First Name"
                    rules="required"
                  >
                    <input
                      type="text"
                      class="form-control mr-2"
                      placeholder="First Name"
                      aria-label="First Name"
                      v-model="form.recipient_first_name"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient's Last Name"
                    rules="required"
                  >
                    <input
                      type="text"
                      class="form-control mr-2"
                      placeholder="Last Name"
                      aria-label="Last Name"
                      v-model="form.recipient_last_name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <div class="mb-2">
                <validation-provider
                  #default="{ errors }"
                  name="Recipient's Phone Number"
                  rules="required"
                >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Phone Number"
                    aria-label="Phone Number"
                    v-model="form.recipient_phone_number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <b-row class="mb-2">
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient's Unit No."
                    rules="required"
                  >
                    <input
                      type="text"
                      class="form-control mr-2"
                      placeholder="Unit No."
                      aria-label="Unit No."
                      v-model="form.recipient_unit_number"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient's Address Line 1"
                    rules="required"
                  >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Address Line 1"
                      aria-label="Address Line 1"
                      v-model="form.recipient_address_line_1"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <div class="mb-2">
                <validation-provider
                  #default="{ errors }"
                  name="Recipient's Address Line 2"
                  rules="required"
                >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Address Line 2"
                    aria-label="Address Line 2"
                    v-model="form.recipient_address_line_2"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <b-row class="mb-2">
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient's Postcode"
                    rules="required"
                  >
                    <input
                      type="text"
                      class="form-control mr-2"
                      placeholder="Postcode"
                      aria-label="Postcode"
                      v-model="form.recipient_postcode"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient's Area"
                    rules="required"
                  >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Area"
                      aria-label="Area"
                      v-model="form.recipient_area"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient's State"
                    rules="required"
                  >
                    <select
                      class="form-control form-select rounded mr-2"
                      v-model="form.recipient_state"
                    >
                      <option value="" disabled selected>State</option>
                      <option v-for="(state, index) in state" :key="index">
                        {{ state.state_name }}
                      </option>
                    </select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Recipient's Country"
                    rules="required"
                  >
                    <select
                      class="form-control form-select rounded"
                      v-model="form.recipient_country"
                    >
                      <option value="" disabled selected>Country</option>
                      <option v-for="(country, index) in country" :key="index">
                        {{ country.value }}
                      </option>
                    </select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-card>
      <b-card>
        <div class="d-flex justify-content-center">
          <div
            class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
          >
            <feather-icon icon="BoxIcon" size="35" color="white" />
          </div>
          <h3 class="mr-5 text">Product Details</h3>
          <div class="w-75">
            <b-row class="mb-2">
              <b-col cols="4">
                <validation-provider
                  #default="{ errors }"
                  name="Weight (kg)"
                  rules="required|positive"
                >
                  <input
                    type="text"
                    class="form-control mr-2"
                    placeholder="Weight (kg)"
                    aria-label="Weight (kg)"
                    v-model="form.item_weight"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col cols="4">
                <validation-provider
                  #default="{ errors }"
                  name="Quantity"
                  rules="required|positive"
                >
                  <input
                    type="text"
                    class="form-control mr-2"
                    placeholder="Quantity"
                    aria-label="Quantity"
                    v-model="form.quantity"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col cols="4">
                <validation-provider
                  #default="{ errors }"
                  name="Remarks"
                  rules="required"
                >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Remarks"
                    aria-label="Remarks"
                    v-model="form.remarks"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-card>
      <div class="d-flex justify-content-center align-items-center mt-3">
        <b-button
          @click="onSubmit"
          variant="gradient-primary"
          v-b-modal.modal-center
          class="box-shadow-1 px-5"
          >Submit</b-button
        >
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import { required } from "@validations";

export default {
  components: {
    extend,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },
  data() {
    return {
      required,
      form: {
        user_id: "",
        temperature: "",
        sender_state: "",
        sender_country: "",
        recipient_state: "",
        recipient_country: "",
        order_type: "DHL",
        payment_method: "CASH",
      },
      optionInput: 0,
      product_category: "",
      optionPackaging: "",
      optionRemarks: "",
      address: [],
      state: [],
      country: [
        {
          title: "Malaysia",
          value: "Malaysia",
        },
        {
          title: "Singapore",
          value: "Singapore",
        },
      ],
      product: [
        {
          title: "Frozen Food",
          value: "Frozen Food",
        },
        {
          title: "Frozen Meat",
          value: "Frozen Meat",
        },
        {
          title: "Fruits",
          value: "Fruits",
        },
        {
          title: "Vegetables",
          value: "Vegetables",
        },
        {
          title: "Dry Goods",
          value: "Dry Goods",
        },
        {
          title: "Others",
          value: "Others",
        },
      ],
      packaging: [
        {
          title: "Carton Box",
          value: "Carton Box",
        },
        {
          title: "Foam Box",
          value: "Foam Box",
        },
        {
          title: "Plastic",
          value: "Plastic",
        },
        {
          title: "Crate",
          value: "Crate",
        },
        {
          title: "Normal Box",
          value: "Normal Box",
        },
        {
          title: "Others",
          value: "Others",
        },
      ],
      remarks: [
        {
          title: "Fragile",
          value: "Fragile",
        },
        {
          title: "Contains Alcohol",
          value: "Contains Alcohol",
        },
        {
          title: "Special Handling",
          value: "Special Handling",
        },
        {
          title: "Non Halal Items",
          value: "Non Halal Items",
        },
        {
          title: "Others",
          value: "Others",
        },
      ],
      sample: [
        {
          title: "Ambiance",
          value: "Ambiance",
        },
        {
          title: "Chill",
          value: "Chill",
        },
        {
          title: "Frozen",
          value: "Frozen",
        },
      ],
    };
  },
  async mounted() {
    await this.$http.get(`getOutlet`).then((response) => {
      this.address = response.data;
    });
    this.$http.get(`state`).then((response) => {
      this.state = response.data.data;
    });
  },
  created() {
    if (this.$route.params.data) {
      this.form = this.$route.params.data;
    }
  },
  methods: {
    onSubmit() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.form.product_category == "Others") {
            this.form.product_category = this.product_category;
          }
          if (this.form.packaging == "Others") {
            this.form.packaging = this.optionPackaging;
          }
          if (this.form.remarks == "Others") {
            this.form.remarks = this.optionRemarks;
          }
          this.$router.push({
            name: "dhl-order-finalize",
            params: { data: this.form, address: this.address },
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.icon {
  background-color: #0f4392;
  height: 50px;
  width: 50px;
}
.text {
  width: 15rem;
}
.notActive {
  background-color: rgba(211, 18, 100, 0.58);
}
</style>
